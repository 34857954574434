import(/* webpackMode: "eager" */ "/opt/script/frontend/senegaljoogjob/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/script/frontend/senegaljoogjob/src/components/home/espace.module.css");
;
import(/* webpackMode: "eager" */ "/opt/script/frontend/senegaljoogjob/src/components/home/fonction.module.css");
;
import(/* webpackMode: "eager" */ "/opt/script/frontend/senegaljoogjob/src/components/home/hero.module.css");
;
import(/* webpackMode: "eager" */ "/opt/script/frontend/senegaljoogjob/src/components/home/missionObjectif.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/script/frontend/senegaljoogjob/src/components/home/slider/Slider.js");
